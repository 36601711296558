import { Component } from '@angular/core';
import {RestService} from "./services/rest.service";
import {formatDistance} from "date-fns/esm";
import {ModalController, ToastController} from "@ionic/angular";
import {ProjectDetailComponent} from "./project-detail/project-detail.component";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public quotes = [
    'Do unto others.',
    'Just like mama used to make.',
    'Do not unto others.',
    'Honey you got a big storm comin',
    'Please see the description below for more information',
    'Your CD Tray is not a cup holder',
    'No I cannot help you clear your browser cache',
    'No I am not in India',
    'You may call this a waste of space, I call it motivation',
    'We taught rocks to think'
  ]

  randomQuote(){
    this.selectedQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  }

  minutesAgo(dateC: any) {
    return formatDistance(    new Date(dateC),
      new Date(),
      { addSuffix: true });
  }

  selectedQuote = '';

  workers: any[] = [];
  projQ: string = "";
  constructor(
    private rest: RestService,
    private toastController: ToastController,
    private modalController: ModalController
  ) {
    this.randomQuote();
    this.rest.get('workers/?stale=false')
      .subscribe(
        res => {
          this.workers = res;
        },
        err => {
          console.log(err);
        }
      );
    setInterval(() => {
      this.rest.get('workers/?stale=false')
        .subscribe(
          res => {
            let changed = false;
            for (let i = 0; i < this.workers.length; i++) {
              if(res[i].name !== this.workers[i].name) {
                changed = true;
                break;
              }
            }
            if(changed) {
              this.workers = res;
            }
          },
          err => {
            console.log(err);
          }
        );
    }, 15000);
  }

  enterDetector($event: KeyboardEvent) {
    if($event.key === 'Enter') {
      this.rest.get('projects/'+this.projQ+'/')
        .subscribe(
          async (res) => {
            const modal = await this.modalController.create({
              component: ProjectDetailComponent,
              componentProps: {
                project: res,
                isFloaty: true
              }
            });
            modal.present();

            const {data, role} = await modal.onWillDismiss();
          },
          async (err) => {
            let t = await this.toastController.create({
              message: `Project not found by ID.`,
              duration: 3000,
              position: 'top'
            });
            t.present();
          }
        )
    }
  }
}
