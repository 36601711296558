import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {FlowsComponent} from './pages/flows/flows.component';
import {FlowDetailComponent} from './flow-detail/flow-detail.component';
import {ProjectsComponent} from "./pages/projects/projects.component";
import {ProjectDetailComponent} from "./project-detail/project-detail.component";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [AppComponent, FlowsComponent, FlowDetailComponent, ProjectsComponent, ProjectDetailComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
