import { Component, OnDestroy, OnInit } from '@angular/core';
import {RestService} from '../../services/rest.service';

@Component({
  selector: 'app-flows',
  templateUrl: './flows.component.html',
  styleUrls: ['./flows.component.scss'],
})
export class FlowsComponent implements OnInit, OnDestroy {

  activeFlows: any[] = [];
  projectList: any[] = [];
  timerFunc: any;
  reload = false;
  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    this.reload = true;
    this.rest.get('flows-detailed/?done=false')
      .subscribe(
        (res) => {
          this.activeFlows = res;
          console.log(res);
          this.reload = false;
        }
      );
    this.timerFunc = setInterval(() => {
      this.reload = true;
      this.rest.get('flows-detailed/?done=false')
        .subscribe(
          (res) => {
            let changed = false;
            if(this.activeFlows.length === 0) {
              changed = true;
            }
            for (let i = 0; i < res.length; i++) {
              try{
              if(res[i].id !== this.activeFlows[i].id){
                changed = true;
                break;
              }
              }catch (e) {
                changed = true;
                break;
              }
            }
            if(changed){
              console.log('New Flow List.');
              this.activeFlows = res;
            }
            this.reload = false;
          }
        );
    }, 10000);
  }
  ngOnDestroy() {
    this.activeFlows = [];
    clearInterval(this.timerFunc);
  }
}
