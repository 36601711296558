import {Component, Input, OnInit} from '@angular/core';
import {RestService} from "../services/rest.service";
import {format, formatDistance, differenceInMinutes} from "date-fns/esm";

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit {

  @Input() project: any;
  @Input() isFloaty: boolean = false;

  flows: any[] = [];
  recentFlows: any[] = [];
  detFlow = {
    a: 'Calculation',
    b: 'Baseline',
    c: 'MonteCarlo',
    d: 'Paid Risk'
  };

  constructor(
    private rest: RestService,
  ) { }

  ngOnInit() {
    this.rest.get('flows-detailed/?done=false&project='+this.project.project_id)
      .subscribe(
        (res) => {
          this.flows = res;
        }
      )
    this.rest.get('flows-detailed/?done=true&project='+this.project.project_id)
      .subscribe(
        (res) => {
          this.recentFlows = res.slice(0,4);
        }
      )
  }

  determineColor(update: any) {
    if(update.needs_simulation && !update.completed_simulation) {
      return 'danger';
    }
    if(!update.needs_simulation && update.completed_simulation) {
      return 'success';
    }
    if(update.needs_simulation && update.completed_simulation) {
      return 'secondary';
    }
    return 'primary';
  }

  detFloaty() {
    if(!this.isFloaty){
      return 'max-height: 200px; overflow-y: scroll;'
    }
    return '';
  }

  since(date_created: any) {
    return formatDistance(    new Date(date_created),
      new Date(),
      { addSuffix: true });
  }

  prettyDate(date_created: any) {
    return format(new Date(date_created), 'yyyy-MM-dd HH:mm') || "N/A";
  }

  duration(start: string, end: string) {
    return differenceInMinutes(new Date(end), new Date(start))
  }

  isCardyFloaty() {
    if(this.isFloaty){
      return 'overflow-y: scroll';
    }
    return '';
  }
}
