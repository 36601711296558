import { Component, OnInit } from '@angular/core';
import {RestService} from "../../services/rest.service";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  reload: boolean = true;
  activeProjects: any[] = [];

  constructor(
    private rest: RestService,
  ) { }

  ngOnInit() {

    this.rest.get('projects/')
      .subscribe(
        (res) => {
          this.activeProjects = res;
          this.reload = false;
        }
      )

  }

}
