import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { RestService } from '../services/rest.service';
import { compareAsc, format, formatDistance } from 'date-fns/esm';
import {ModalController, ToastController} from "@ionic/angular";
import {ProjectDetailComponent} from "../project-detail/project-detail.component";
@Component({
  selector: 'app-flow-detail',
  templateUrl: './flow-detail.component.html',
  styleUrls: ['./flow-detail.component.scss'],
})
export class FlowDetailComponent implements OnInit, OnDestroy {

  @Input() flow: any;
  messages: any[] = [];
  timerFunc: any;
  detFlow = {
    a: 'Calculation',
    b: 'Baseline',
    c: 'MonteCarlo',
    d: 'Paid Risk'
  };



  constructor(
    private rest: RestService,
    private toastController: ToastController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.rest.get('messages/?flow='+this.flow.id)
      .subscribe(
        (res) => {
          if(this.messages !== res){
            this.messages = res;
          }

        }
      );
    this.timerFunc = setInterval(() => {
      this.rest.get('messages/?flow='+this.flow.id)
        .subscribe(
          (res) => {
            this.messages = res;
          }
        );
    }, 5000);
  }

  ngOnDestroy() {
    this.messages = [];
    clearInterval(this.timerFunc);
  }

  prettyDate(dateC: any) {
    return format(new Date(dateC), 'yyyy-MM-dd HH:mm');
  }

  minutesAgo(dateC: any) {
    return formatDistance(    new Date(dateC),
      new Date(),
      { addSuffix: true });
  }

  markStale() {
    this.rest.patch('flows/'+this.flow.id+'/', {stale: true, done: true})
      .subscribe(
        (res) => {
          console.log('Done');
          this.toastController.create({
            message: `Marked Flow '${this.flow.id}@${this.flow.worker.name}' as Stale. This flow will be removed shortly.`,
            duration: 3000,
          });
        },
        (err) => {
          console.log('Error', err);
        }
      );
  }

  showRecentProjectFlows(flow) {
    this.rest.get('projects/'+flow.project.project_id+'/')
      .subscribe(
        async (res) => {
          const modal = await this.modalController.create({
            component: ProjectDetailComponent,
            componentProps: {
              project: res,
              isFloaty: true
            }
          });
          modal.present();

          const {data, role} = await modal.onWillDismiss();
        }
      )
  }

  showRecentWorkerFlows() {

  }

  markStaleAndRerun() {
    this.rest.patch('flows/'+this.flow.id+'/', {stale: true, done: true})
      .subscribe(
        (res) => {
          console.log('Done');
          this.rest.recalc(this.flow)
            .subscribe(
              (res) => {
                this.toastController.create({
                  message: 'Sent the project to recalculation. Please wait for the UI to be updated. It will take a moment for a worker to pick up the new job.',
                  duration: 3000,
                });
              },
              (err) => {
                console.log('Error', err);
                this.toastController.create({
                  message: 'An error occured while sending to recalculation. Check console logs.',
                  duration: 3000,
                });
              }
            );

        },
        (err) => {
          console.log('Error', err);
          this.toastController.create({
            message: 'An error occured while communicating with the Controller. Check console logs.',
            duration: 3000,
          });
        }
      );
  }
}
