import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RestService {

  baseUrl = 'https://controller.analytics.rhinoworks.dev/api/';

  options = {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: 'Token b98d014a197b55c2e85bbf8965ccb737b4304965'
    }
  };

  constructor(
    private http: HttpClient
  ) { }

  get(uri: string): Observable<any> {
    return this.http.get(this.baseUrl+uri, this.options);
  }
  post(uri: string, data: any): Observable<any> {
    return this.http.post(this.baseUrl+uri, data, this.options);
  }
  patch(uri: string, data: any): Observable<any> {
    return this.http.patch(this.baseUrl+uri, data, this.options);
  }

  connectorBackends = {
    prod: "https://production.connector.rhinoworks.dev/",
    stage: "https://aegis-dashboards-backend-bleed.herokuapp.com/",
    bleeding: "https://aegis-dashboards-backend-bleed.herokuapp.com/"
  }

  recalc(flow) {
    let o = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Authorization": "API-Token YWRzZmRzYWZkc2FmZGFzZmRzZmdkc2FnZmRnYWZnZmRnc2Zkc2dmZHNoZmRoZmRoZmRm",
        "Content-Type": "application/json"
      }
    };
    return this.http.post(`${this.connectorBackends[flow.worker.environment]}report/calculate/${flow.project.project_id}`, {}, o)
  }
}
